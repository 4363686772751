import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  DialogActions,
  Dialog,
  DialogContent,
  Button,
  Autocomplete,
  DialogTitle,
  TextField
} from '@mui/material';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import UserContext from '../../../../context';
import { useCustomLazyQuery } from '../../../../hooks/graphql';
import { useDebouncedCallback } from 'use-debounce';

const CUSTOM_ATTRIBUTE_STRING_VALUES = gql`
  query AttribCustomAtributes(
    $from: String!
    $to: String!
    $timezone: String!
    $conversionMethodology: String!
    $attributeId: String!
    $attributeDimension: AttributionPingsCustomAttributeDimension!
    $view: AttributionPingsCustomAttributeView!
    $listSize: Int
    $listOffset: Int
    $listSort: String
    $listOrder: String
    $isStringType: Boolean!
    $search: String
    $clientId: ID!
    $detailsGranularity: String!
    $visitDefinition: AttributionPingsVisitDefinition!
    $conversionRateBase: ConversionRateBase
    $viewId: ID
  ) {
    datePickerFrom @client @export(as: "from")
    datePickerTo @client @export(as: "to")
    client(clientId: $clientId) {
      id
      attributionPings(viewId: $viewId, from: $from, to: $to) {
        id
        summaryMetrics(
          from: $from
          to: $to
          detailsGranularity: $detailsGranularity
          timezone: $timezone
          conversionMethodology: $conversionMethodology
          visitDefinition: $visitDefinition
          conversionRateBase: $conversionRateBase
        ) {
          customAttribute(
            from: $from
            to: $to
            timezone: $timezone
            attributeId: $attributeId
            attributeDimension: $attributeDimension
            conversionMethodology: $conversionMethodology
            view: $view
            listSize: $listSize
            listOffset: $listOffset
            listSort: $listSort
            listOrder: $listOrder
            search: $search
          ) {
            id
            stringList @include(if: $isStringType) {
              details {
                value
              }
            }
          }
        }
      }
    }
  }
`;

const CustomAttributeModal = ({
  id,
  handleClose,
  isOpen,
  viewFilters,
  selectedFilters,
  handleOnUpdate
}) => {
  const { t } = useTranslation();

  const { clientSettings } = useContext(UserContext);
  const [searchValue, setSearchValue] = useState(null);

  const customAttributeData = useMemo(
    () =>
      clientSettings.attributionPingsSettings.customAttributes.find(
        ca => ca.internalAttributKey === id
      ),
    [clientSettings.attributionPingsSettings.customAttributes, id]
  );
  const [values, setValues] = useState(
    selectedFilters?.find(
      ({ type }) =>
        type ===
        `${
          customAttributeData.dimension === 'conversion'
            ? 'customAttribute'
            : 'audienceDimension'
        }_${customAttributeData.type}_${id}`
    )?.ids || []
  );

  const { loadLazy, loading, data } = useCustomLazyQuery(
    CUSTOM_ATTRIBUTE_STRING_VALUES,
    {
      variables: {
        clientId: clientSettings.id,
        conversionMethodology:
          clientSettings.attributionPingsSettings.conversionMethodology,
        timezone: clientSettings.timezone,
        attributeId: id,
        attributeDimension: customAttributeData.dimension,
        listSort: 'valueParticipation',
        listOrder: 'desc',
        view: 'string_list',
        search: searchValue,
        listSize: 100,
        listOffset: 0,
        isStringType: customAttributeData.type === 'string'
      },
      skip:
        !customAttributeData ||
        !isOpen ||
        customAttributeData.type !== 'string',
      fetchPolicy: 'no-cache'
    }
  );

  const [debouncedCallback] = useDebouncedCallback(value => {
    loadLazy();
  }, 1000);

  useEffect(() => {
    loadLazy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const attributeFilterId = useMemo(
    () => `customAttribute_${customAttributeData.type}_${id}`,
    [customAttributeData, id]
  );

  const optionsList = useMemo(() => {
    if (customAttributeData.type === 'string') {
      if (!data) {
        return [];
      }

      return data?.client?.attributionPings?.summaryMetrics?.customAttribute?.stringList?.details
        ?.filter(
          ({ id }) =>
            !viewFilters?.some(({ type }) => type === attributeFilterId) ||
            viewFilters?.some(
              ({ type, ids }) => type === attributeFilterId && ids.includes(id)
            )
        )
        .map(({ value }) => value);
    }

    if (customAttributeData.type === 'boolean') {
      return ['true', 'false']?.filter(
        id =>
          !viewFilters?.some(({ type }) => type === attributeFilterId) ||
          viewFilters?.some(
            ({ type, ids }) => type === attributeFilterId && ids.includes(id)
          )
      );
    }
  }, [attributeFilterId, customAttributeData, data, viewFilters]);

  return (
    <Dialog maxWidth="sm" fullWidth onClose={handleClose} open={isOpen}>
      <DialogTitle>
        {t(
          `ma.filter.filterBy${
            customAttributeData.dimension === 'conversion'
              ? 'CustomAttribute'
              : 'AudienceDimension'
          }`,
          {
            name: customAttributeData.name
          }
        )}
      </DialogTitle>

      <DialogContent>
        <Autocomplete
          multiple
          filterOptions={x => x}
          value={values}
          onChange={(_, v) => {
            setValues(v);
          }}
          inputValue={searchValue || ''}
          onInputChange={(event, newInputValue, reason) => {
            if (event) {
              debouncedCallback(newInputValue);
              setSearchValue(newInputValue);
            }
          }}
          noOptionsText={loading ? t('commun.dataLoading') : t('commun.noData')}
          id="tags-standard"
          options={optionsList}
          getOptionLabel={option => option}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label=""
              placeholder={t(
                `ma.commun.select${
                  customAttributeData.dimension === 'conversion'
                    ? 'CustomAttribute'
                    : 'AudienceDimension'
                }`,
                {
                  name: customAttributeData.name
                }
              )}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t('commun.cancel')}
        </Button>
        <Button
          disabled={values?.length <= 0}
          onClick={() => {
            handleOnUpdate(
              `${
                customAttributeData.dimension === 'conversion'
                  ? 'customAttribute'
                  : 'audienceDimension'
              }_${customAttributeData.type}_${id}`,
              values
            );
            handleClose();
          }}
          color="primary"
        >
          {t('commun.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CustomAttributeModal.propTypes = {
  id: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleOnUpdate: PropTypes.func.isRequired,
  viewFilters: PropTypes.array.isRequired,
  selectedFilters: PropTypes.array.isRequired
};

export default CustomAttributeModal;
