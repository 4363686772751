import { gql, useApolloClient } from '@apollo/client';
import { Select, FormControl, MenuItem } from '@mui/material';
import { useAtom } from 'jotai';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext, { analyticsFiltersAtom } from '../../../context';
import { useLocalApolloData } from '../../../hooks/localApolloClient';
import { truncateWithEllipses } from '../../../helpers/string';

const ClientSelect = () => {
  let navigate = useNavigate();
  const { clientsAccess, clientSettings, selectedView } = useContext(
    UserContext
  );
  const [, setAnalyticsFilters] = useAtom(analyticsFiltersAtom);
  const client = useApolloClient();
  const { localGlobalLoading } = useLocalApolloData();

  return (
    <FormControl fullWidth variant="standard">
      <Select
        sx={{ fontSize: '0.8rem' }}
        labelId="thankyou-active-clientid-label"
        id="thankyou-active-clientid"
        value={clientSettings.id}
        disabled={localGlobalLoading.length > 0}
        placeholder="Compte"
        renderValue={() => truncateWithEllipses(clientSettings.name, 32)}
        onChange={e => {
          // If current page is an order page on affiliates product, redirect to the orders page to avoid graphql error
          if (
            window.location.pathname.includes('/affiliate-marketing/orders/')
          ) {
            navigate('/affiliate-marketing/orders');
          }

          // If current page is an affiliate details page on affiliates product, redirect to the affiliates list page to avoid graphql error
          if (
            window.location.pathname.includes(
              '/affiliate-marketing/affiliates/'
            )
          ) {
            navigate('/affiliate-marketing/affiliates');
          }

          // Force the selected view for the new client in the url
          // to avoid replacing it with the previous client's view id
          if (window.location.pathname.includes('/view/')) {
            const clientSelected = clientsAccess.find(
              ({ clientId }) => clientId === e.target.value
            );

            navigate(
              window.location.pathname.replace(
                `/view/${selectedView}`,
                `/view/${clientSelected.selectedView}`
              )
            );
          } else {
            navigate(window.location.pathname);
          }

          client.writeQuery({
            query: gql`
              query ClientData {
                clientId @client
                selectedAffiliatePlatformIds @client
                dynamicVariables @client {
                  name @client
                  values @client
                }
                filterSentToPlatform @client
              }
            `,
            data: {
              clientId: e.target.value,
              selectedAffiliatePlatformIds: null,
              dynamicVariables: [],
              filterSentToPlatform: [],
              filterClosingStatuses: []
            }
          });

          setAnalyticsFilters([]);
        }}
      >
        {[...clientsAccess]
          .sort((a, b) => a.clientName.localeCompare(b.clientName))
          .map(item => (
            <MenuItem key={item.clientId} value={item.clientId}>
              {item.clientName}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default React.memo(ClientSelect);
